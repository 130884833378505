













































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ROUTE_DEAL } from '@/router/routes';
import Deal from '@/models/Deal.model';
import DealTrackingData from '@/models/DealTrackingData.model';
import { mixins } from 'vue-class-component';
import BaseMixin from '@/misc/BaseMixin.mixins';

@Component
export default class TrackingDataListComponent extends mixins(BaseMixin) {
  @Prop({ required: true })
  public data!: DealTrackingData[];

  @Prop({ default: false })
  private isLoading!: boolean;

  @Prop({ required: true })
  private getProgressBarColor!: (value: number) => string;

  // Thresholds for colors in progressbar: 
  private THRESHOLD_GREEN = BaseMixin.THRESHOLD_GREEN;
  private THRESHOLD_RED = BaseMixin.THRESHOLD_RED;

  private headers = [
    { text: this.$t('DEAL.TITLE'), align: 'start', sortable: true, value: 'deal.title' },
    { text: this.$t('DEAL.ACTIVE'), align: 'center', sortable: true, value: 'deal.status' },
    { text: this.$t('TRACKING.NUMBER_OF_CLICKS'), align: 'center', sortable: true, value: 'numberOfClicks' },
    { text: this.$t('TRACKING.NUMBER_OF_USERS'), align: 'center', sortable: true, value: 'numberOfUsers' },
    { text: this.$t('TRACKING.REMAINING_BUDGET'), align: 'center', sortable: true, value: 'remainingBudget' },
  ];

  private openDetail(deal: Deal) {
    this.$router.push({ name: ROUTE_DEAL, params: { dealId: deal.id! } });
  }
}
